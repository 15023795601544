import React, {useState} from "react";
import "./MateriaalOverview.scss";
import usePrivateApi from "../../hooks/usePrivateApi";
import OverviewTable from "../../components/OverviewTable/OverviewTable";
import StyledSnackbar from "../../components/StyledSnackbar/StyledSnackbar";
import MateriaalMobile from "./Materiaal/MateriaalMobile";

function MateriaalOverviewMobile() {

    const [message, setMessage] = useState({});

    const api = usePrivateApi();

    return (
        <div className="MateriaalOverview">
            <h1 className={"h1Mobile"}>{"Voorraad"}
            </h1>
            <div className="Materiaal">
                <OverviewTable
                    dataFetchCall={api.getAllMateriaal}
                    withFilter={false}
                    withSearch={true}
                    withFooter={true}
                    withTotalValue={false}
                    isMobile={true}
                    deletedId={null}
                    resetOpenRow={()=> {}}
                    headers={[
                        {displayName: 'Omschrijving', className: 'ninety alignLeft mobile', sortName: 'omschrijving', colSpan: 6},
                        {displayName: '#', className: "seven alignCenter mobile", sortName: 'voorraad', colSpan: 1},
                    ].filter(x => x !== undefined)}
                    mapDataRow={row => (
                        <MateriaalMobile
                            initialMateriaal={row}
                            key={row.id}
                            showMessage={setMessage}/>)
                    }
                />
                {/*<hr/>*/}
                {/*{"iaro "+JSON.stringify(isAnyRowOpen)}*/}
            </div>
            <StyledSnackbar message={message.value} type={message.type} resetMessage={() => setMessage({})}/>
        </div>
    );
}

export default MateriaalOverviewMobile;
