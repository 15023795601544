import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import "./MateriaalNieuw.scss";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import usePrivateApi from "../../../hooks/usePrivateApi";
import StyledSnackbar from "../../../components/StyledSnackbar/StyledSnackbar";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import { validateMateriaal } from "../../../utils/util";

const MateriaalNieuw = (() => {

    const navigate = useNavigate();
    const returnTo = window.location.search.split("=")[1]

    const api = usePrivateApi();

    let initialMateriaal = {
        isValid: true,
        eanCode: "",
        artikelnummer: "",
        omschrijving: "",
        inkoopPrijs: "0",
        prijs: "0",
        voorraad: "0",
        minVoorraad: "0",
        maxVoorraad: "0",
        btw: "hoog"
    }

    const [formFieldValues, setFormFieldValues] = useState(initialMateriaal);
    const [isInEditMode, setIsInEditMode] = useState(true);
    const [message, setMessage] = useState({});

    const [materiaal, setMateriaal] = useState(initialMateriaal);

    useEffect(() => {
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                saveChanges()
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    });

    useEffect(() => {
        const listener = event => {
            if (event.code === "Escape" && isInEditMode) {
                cancelChanges()
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    });

    const toggleEditMode = () => {
        setIsInEditMode(!isInEditMode);
    };

    const changeMateriaalState = (key, value) => {
        setFormFieldValues({
            ...formFieldValues,
            [key]: value
        });
    };

    const cancelChanges = () => {
        navigate("/materiaal/");
    };

    const saveChanges = async () => {
        var validatedMateriaal = validateMateriaal({ ...formFieldValues })
        if (validatedMateriaal.isValid) {
            api.existsMateriaalByEancode(validatedMateriaal.eanCode)
                .then((eanExists) => {
                    if (eanExists) {
                        setMessage({ value: `EanCode ${validatedMateriaal.eanCode} bestaat al, wijzig de bestaande of kies een andere.`, type: 'error' })
                    } else {
                        api.updateMateriaal(materiaal.id ? materiaal.id : 0, validatedMateriaal)
                            .then(
                                (materiaal) => {
                                    setMessage({ value: 'Materiaal is opgeslagen', type: 'success' });
                                    toggleEditMode();
                                    setMateriaal(materiaal)
                                    if (!!returnTo) {
                                        addMateriaalRegelToOnderhoud(returnTo, materiaal)
                                    } else
                                        navigate("/materiaal");
                                },
                                (e) => setMessage({ value: `EanCode checken is niet gelukt, neem contact op met Ruud`, type: 'error' })
                            );
                    }
                },
                    (e) => setMessage({ value: `Failed to update materiaal: ${e}`, type: 'error' }))
        } else {
            setMessage({ value: "Materiaal is ongeldig.", type: 'error' })
        }
    }

    const addMateriaalRegelToOnderhoud = (onderhoudId, materiaal) => {
        const onderhoudMateriaalRegel = {
            "id": null,
            "aantal": 1,
            "omschrijving": materiaal.omschrijving,
            "prijs": materiaal.prijs,
            "totaalPrijs": materiaal.prijs,
            "regelSjabloonId": materiaal.id
        };
        api.updateOnderhoudRegel("materiaal", onderhoudId, 0, onderhoudMateriaalRegel)
            .then(
                () => {
                    navigate("/onderhoud/" + returnTo)
                },
                (e) => setMessage({ value: `Failed to update materiaal: ${e}`, type: 'error' }))
    };

    const fieldNames = ["eanCode", "artikelnummer", "leverancier", "locatie", "omschrijving", "inkoopPrijs", "prijs", "voorraad", "minVoorraad", "maxVoorraad", "btw"]

    const capitalize = () => {
        changeMateriaalState("omschrijving",
            formFieldValues.omschrijving.split(" ").map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
        )
    }

    const exBtw = () => {
        const inkoopPrijs = Math.round(((formFieldValues.inkoopPrijs.toString().replace(',', '.') / 1.21) + Number.EPSILON) * 100) / 100
        changeMateriaalState("inkoopPrijs", inkoopPrijs)
    }

    return (
        <div className={"MateriaalMateriaal"}>
            <h1 className={"capitalize"}>Materiaal</h1>
            <table>
                <tbody>
                    <tr>
                        {fieldNames.map(fieldName => (
                            <th className={"capitalize"} key={fieldName}>{fieldName}</th>
                        ))}
                    </tr>

                    {isInEditMode && (
                        <React.Fragment>
                            <tr className={"materiaal EditMode"}>
                                {fieldNames.map(fieldName => (
                                    <td key={fieldName}>
                                        <TextField
                                            autoFocus={fieldName === 'eanCode'}
                                            variant={"standard"}
                                            className="variable-details__fullwidth"
                                            value={formFieldValues[fieldName]}
                                            onChange={(e) => changeMateriaalState(fieldName, e.target.value)}
                                            type="text"
                                        />
                                    </td>
                                ))}
                            </tr>
                            <tr className={"EditMode"}>
                                <td colSpan={10}>
                                    <Tooltip title="Annuleer" placement={"top"}>
                                        <IconButton onClick={cancelChanges}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Bewaar" placement={"top"}>
                                        <IconButton onClick={saveChanges}>
                                            <SaveAltIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Title Case" placement={"top"}>
                                        <IconButton onClick={capitalize}>
                                            <TextFieldsIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Inkoop excl. BTW" placement={"top"}>
                                        <IconButton onClick={exBtw}>
                                            <PriceChangeIcon />
                                        </IconButton>
                                    </Tooltip>
                                </td>
                            </tr>
                        </React.Fragment>)}
                </tbody>
            </table>
            <StyledSnackbar message={message.value} type={message.type} resetMessage={() => setMessage({})} />
            {/*<hr/>*/}
            {/*{"[0] "+JSON.stringify(materiaal)}*/}
            {/*<hr/>*/}
            {/*{"[1] "+JSON.stringify(formFieldValues)}*/}
        </div>
    );
});

export default MateriaalNieuw;
