import React, { useEffect, useRef, useState } from "react";
import "./Materiaal.scss";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import usePrivateApi from "../../../hooks/usePrivateApi";
import DeleteIcon from '@mui/icons-material/Delete';
import EanQRCode from "../../../components/QRCode/EanQRCode";
import EanQRPrintCode from "../../../components/QRCode/EanQRPrintCode";
import { useReactToPrint } from "react-to-print";
import { formatCurrency, truncateText, validateMateriaal } from "../../../utils/util";


const Materiaal = (({
    initialMateriaal,
    showInkoop,
    showEan,
    requestOpenRow,
    isBestelAdvies,
    isQr,
    removeMateriaal,
    showMessage
}) => {

    const api = usePrivateApi();

    const [formFieldValues, setFormFieldValues] = useState(initialMateriaal);
    const [materiaal, setMateriaal] = useState(initialMateriaal);
    const [isInEditMode, setIsInEditMode] = useState(false);

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    useEffect(() => {
        setMateriaal(initialMateriaal);
    }, [initialMateriaal]);

    useEffect(() => {
        const listener = event => {
            if (isInEditMode && (event.code === "Enter" || event.code === "NumpadEnter")) {
                saveChanges()
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    });

    useEffect(() => {
        const listener = event => {
            if (event.code === "Escape" && isInEditMode) {
                cancelChanges()
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    });

    const toggleEditMode = () => {
        if (requestOpenRow(isInEditMode ? 'close' : 'open'))
            setIsInEditMode(!isInEditMode);
    };

    const changeMateriaalState = (key, value) => {
        setFormFieldValues({
            ...formFieldValues,
            [key]: value
        });
    };

    const cancelChanges = () => {
        setFormFieldValues(materiaal);
        toggleEditMode();
    };

    const saveChanges = async () => {
        var validatedMateriaal = validateMateriaal(formFieldValues)
        if (validatedMateriaal.isValid) {
            api.updateMateriaal(materiaal.id, validatedMateriaal)
                .then(
                    (data) => {
                        toggleEditMode();
                        setMateriaal(data);
                        setFormFieldValues(data);
                        showMessage({ value: 'Materiaal is opgeslagen', type: 'success' });
                    },
                    (e) => showMessage({ value: `Failed to update materiaal: ${e}`, type: 'error' }))
        } else {
            showMessage({ value: 'Materiaal is niet geldig', type: 'error' })
        }
    }

    const deleteMateriaal = (materiaalId) => {
        toggleEditMode()
        removeMateriaal(materiaalId)
    }

    const column1Name = showEan ? "eanCode" : "artikelnummer"

    const fieldNames =
        showInkoop && isBestelAdvies ? [column1Name, "leverancier", "locatie", "omschrijving", "inkoopPrijs", "voorraadWaarde", "prijs", "minVoorraad", "maxVoorraad", "voorraad", "bestelAdvies"] :
            showInkoop && !isBestelAdvies ? [column1Name, "leverancier", "locatie", "omschrijving", "inkoopPrijs", "prijs", "minVoorraad", "maxVoorraad", "voorraad", "btw"] :
                !showInkoop && isBestelAdvies ? [column1Name, "leverancier", "locatie", "omschrijving", "prijs", "minVoorraad", "maxVoorraad", "voorraad", "bestelAdvies"] :
                    [column1Name, "leverancier", "locatie", "omschrijving", "prijs", "minVoorraad", "maxVoorraad", "voorraad", "btw"]

    const columnSpan = (fieldName) => {
        switch (fieldName) {
            case 'artikelnummer':
            case 'leverancier':
                return 1;
            case 'omschrijving':
                return 6;
            default:
                return 1;
        }
    }
    const className = (fieldName) => {
        switch (fieldName) {
            case 'artikelnummer':
            case 'leverancier':
            case 'locatie':
                return 'alignLeft';
            case 'omschrijving':
                return 'alignLeft';
            default:
                return 'alignCenter';
        }
    }
    const isEditable = (fieldName) => {
        switch (fieldName) {
            case "bestelAdvies":
            case "eanCode":
                return false;
            default:
                return true;
        }
    }

    const content = (fieldName) => {
        switch (fieldName) {
            case 'artikelnummer':
            case 'eanCode':
                return isQr ? <EanQRCode size={20} eanCode={materiaal.eanCode} /> : materiaal[fieldName];
            case 'inkoopPrijs':
            case 'prijs':
                return `€ ${formatCurrency(materiaal[fieldName])}`
            case 'omschrijving':
                return truncateText(formFieldValues[fieldName])
            default:
                return materiaal[fieldName];
        }
    }

    const capitalize = () => {
        changeMateriaalState("omschrijving",
            formFieldValues.omschrijving.split(" ").map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(" ")
        )
    }

    return (
        <>
            {!isInEditMode && (
                <tr className={"materiaal OnClick"} onClick={isQr ? handlePrint : toggleEditMode}>
                    {fieldNames.map((fieldName, index) => (
                        <td colSpan={columnSpan(fieldName)}
                            className={className(fieldName)}
                            key={fieldName}>
                            {content(fieldName)}</td>
                    ))}
                    <td className={"hideprint"}>
                        <EanQRPrintCode
                            className="eancode"
                            ref={componentRef}
                            isArbeid={false}
                            arbeidMateriaal={materiaal} />
                    </td>
                </tr>
            )}

            {isInEditMode && (
                <React.Fragment>
                    <tr className={"materiaal EditMode"}>
                        {fieldNames.map((fieldName, index) => (
                            isEditable(fieldName) ? (
                                <td key={fieldName}
                                    className={className(fieldName)}
                                    colSpan={columnSpan(fieldName)}>
                                    <TextField
                                        variant={"standard"}
                                        multiline
                                        className={className(fieldName)}
                                        value={formFieldValues[fieldName]}
                                        onChange={(e) => changeMateriaalState(fieldName, e.target.value)}
                                    />
                                </td>) : (
                                <td colSpan={columnSpan(fieldName)}
                                    className={className(fieldName)}
                                    key={fieldName}>
                                    {content(fieldName)}</td>
                            )
                        ))}
                    </tr>
                    <tr className={"EditMode"}>
                        <td colSpan={fieldNames.length + 4}>
                            <Tooltip title="Annuleer" placement={"top"}>
                                <IconButton onClick={cancelChanges}>
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Bewaar" placement={"top"}>
                                <IconButton onClick={saveChanges}>
                                    <SaveAltIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Title Case" placement={"top"}>
                                <IconButton onClick={capitalize}>
                                    <TextFieldsIcon />
                                </IconButton>
                            </Tooltip>
                        </td>
                        {!isBestelAdvies ? (<td className="alignRight">
                            <Tooltip title="Verwijder" placement={"top"}>
                                <IconButton onClick={() => deleteMateriaal(materiaal.id)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </td>) : <td>&nbsp;</td>}
                    </tr>
                    {/*<tr>*/}
                    {/*    <td colSpan={fieldNames.length + 5}>*/}

                    {/*        <hr/>*/}
                    {/*        {"[0] "+JSON.stringify(materiaal)}*/}
                    {/*        <hr/>*/}
                    {/*        {"[1] "+JSON.stringify(formFieldValues)}*/}

                    {/*    </td>*/}
                    {/*</tr>*/}
                </React.Fragment>)}
        </>
    );
});

export default Materiaal;
