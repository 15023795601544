import React from 'react';
import { QRCodeSVG } from 'qrcode.react'

import './EanQRCode.scss'

const EanQRCode = ({ eanCode, size }) => (
  <div className="auth-qr-code__container">
    <QRCodeSVG fgColor={"#346beb"} size={size} value={`${eanCode}`}/>
  </div>
);

export default EanQRCode;
