import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import "./OnderhoudDetail.scss";
import OnderhoudRegels from "./OnderhoudRegels/OnderhoudRegels";
import OnderhoudHeader from "./OnderhoudHeader/OnderhoudHeader";
import StyledSnackbar from "../../components/StyledSnackbar/StyledSnackbar";
import { editableStates } from "../../utils/util";
import Tooltip from "@mui/material/Tooltip";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import usePrivateApi from "../../hooks/usePrivateApi";
import OnderhoudNotitie from "./OnderhoudNotitie/OnderhoudNotitie";
import OnderhoudDocumentOverview from "./OnderhoudDocumentOverview/OnderhoudDocumentOverview";
import OnderhoudApkOverview from "./OnderhoudApkOverview/OnderhoudApkOverview";


const OnderhoudDetail = (() => {

    const navigate = useNavigate();
    const { id, nieuw } = useParams()
    const api = usePrivateApi();
    const navigateToOnderhoud = () => {
        navigate("/onderhoud");
    }

    const [message, setMessage] = useState({});
    const [onderhoudStatus, setOnderhoudStatus] = useState({});
    const [onderhoud, setOnderhoud] = useState({});
    const [isLoaded, setIsLoaded] = useState(false);
    const [isEditAllowed, setIsEditAllowed] = useState(false);
    const [isAnyRowOpen, setIsAnyRowOpen] = useState(nieuw === 'nieuw');
    const [startInEditMode, setStartInEditMode] = useState(nieuw === 'nieuw');
    const [openNewRow, setOpenNewRow] = useState('none');

    const immutableStatusWarning = `Het onderhoud heeft status '${onderhoudStatus}' en mag dus niet meer worden aangepast`;

    useEffect(() => {
        if (openNewRow !== 'none') {
            addEmptyOnderhoudRegel(openNewRow)
        }
    })

    useEffect(() => {
        api.getOnderhoud(id).then(
            data => {
                setOnderhoud(data);
                setOnderhoudStatus(data.status)
                setIsEditAllowed(editableStates.includes((data.status)));
                setIsLoaded(true);
            }
        ).catch(
            () => {
                setMessage({ value: `Onderhoud niet kunnen ophalen.`, type: 'error' });
                navigateToOnderhoud()
            }
        );
    }, [id, api]);


    const requestEmptyOnderhoudRegel = (rowType) => {
        setOpenNewRow(rowType)
    }

    const deleteOnderhoud = async () => {
        if (window.confirm(`Onderhoud verwijderen?`)) {
            api.removeOnderhoud(id)
                .then(
                    () => {
                        navigateToOnderhoud()
                    },
                    error => {
                        setMessage({
                            value: `Onderhoud is NIET verwijderd: ${error.status}:${error.statusText}`,
                            type: 'error'
                        });
                    }
                );
        }
    };

    const persistOnderhoudHeader = (formFields) => {
        api.updateOnderhoudHeader(onderhoud.id, {
            klantId: formFields.klant ? formFields.klant.id : null,
            fietsId: formFields.fiets ? formFields.fiets.id : null,
            datum: formFields.datum,
            omschrijving: formFields.omschrijving,
            status: formFields.status,
            isSchade: formFields.isSchade
        })
            .then(
                (data) => {
                    setOnderhoud(data);
                    setStartInEditMode(false)
                },
                (e) => setMessage({ value: `Opslaan is mislukt: ${e}`, type: 'error' }))
    };

    const persistOnderhoudFiets = (formFields) => {
        api.updateOnderhoudFiets(onderhoud.id, formFields.fiets.id)
            .then(
                (data) => {
                    setOnderhoud(data);
                    setStartInEditMode(false)
                },
                (e) => setMessage({ value: `Opslaan is mislukt: ${e}`, type: 'error' }))
    };

    const persistOnderhoudNotitie = (n) => {
        api.updateOnderhoudNotitie(onderhoud.id, {
            notitie: n
        })
            .then(
                (data) => {
                    setOnderhoud(data);
                    setStartInEditMode(false)
                },
                (e) => setMessage({ value: `Opslaan is mislukt: ${e}`, type: 'error' }))
    };

    const persistOnderhoudRegel = (rowType, formFields) => {
        if (!formFields.regelSjabloonId)
            if (!formFields.omschrijving)
                setMessage({ value: `Niets gekozen dus niet opgelagen`, type: 'warning' })
            else
                setMessage({ value: `We hadden nu kunnen besluiten een nieuwe te maken`, type: 'success' })

        else if (onderhoud.id) {
            api.updateOnderhoudRegel(rowType, onderhoud.id, formFields.id ? formFields.id : 0, formFields)
                .then(
                    data => {
                        setOnderhoud(data)
                        setMessage({ value: `Onderhoud ${rowType} regel opgeslagen`, type: 'success' });
                        if (!formFields.smeermiddelen && (!formFields.id || formFields.id === 0)) requestEmptyOnderhoudRegel(rowType)
                    },
                    (e) => setMessage({ value: `Opslaan ${rowType} regel is mislukt: ${e}`, type: 'error' }))
        } else {
            setMessage({ value: "????.", type: 'error' })
        }
    }

    const removeOnderhoudRegel = (rowType, id) => {
        if (!id || id === 0) {
            setOnderhoud(onderhoud => ({ ...onderhoud, [rowType]: onderhoud[rowType].filter(row => row.id !== id) }));
        } else if (onderhoud && id !== 0) {
            api.removeOnderhoudRegel(rowType, onderhoud.id, id)
                .then(
                    data => {
                        setOnderhoud(data);
                        setMessage({ value: `Onderhoud ${rowType} regel verwijderd`, type: 'success' });
                    },
                    error => {
                        setMessage({
                            value: `Onderhoud ${rowType} regel is NIET verwijderd: ${error.status}:${error.statusText}`,
                            type: 'error'
                        });
                    }
                );
        }
    }

    const requestOpenRow = (request) => {
        if (onderhoudStatus === 'uitgevoerd' || onderhoudStatus === 'gefactureerd') {
            warnImutable(onderhoudStatus);
            return false
        }
        if (request === 'open' && isAnyRowOpen) {
            setMessage({ value: "Sluit eerst de geopende regel ...", type: 'warning' });
            return false;
        } else {
            setIsAnyRowOpen(request === 'open');
            return true;
        }
    };

    const warnImutable = () => {
        if (onderhoudStatus === 'uitgevoerd') {
            setMessage({ value: `${immutableStatusWarning}, wijzig de status om aan te passen`, type: 'warning' });
        } else {
            setMessage({ value: immutableStatusWarning, type: 'error' });
        }
    };

    const changeOnderhoudStatus = (status) => {
        setOnderhoudStatus(status)
        setIsEditAllowed(editableStates.includes(status))
    }

    const addEmptyOnderhoudRegel = (rowtype) => {
        const allowed = requestOpenRow('open');

        if (allowed && onderhoud) {
            const initial = { id: null, omschrijving: "", prijs: 0, totaalPrijs: 0, editMode: true };
            if (rowtype === "arbeid") {
                initial.tijd = 0;
                initial.btw = "laag"
            }
            if (rowtype === "materiaal") {
                initial.aantal = 1;
                initial.btw = "hoog"
            }
            initial[rowtype] = { omschrijving: "" };
            setOnderhoud(onderhoud => (
                { ...onderhoud, ...onderhoud[rowtype].push(initial) }));
        }
        setOpenNewRow('none')
    }

    return (
        <>
            {isLoaded ?
                <div className="OnderhoudDetail">
                    <OnderhoudHeader
                        onderhoud={onderhoud}
                        requestOpenRow={requestOpenRow}
                        isEditAllowed={isEditAllowed}
                        startInEditMode={startInEditMode}
                        isAnyRowOpen={isAnyRowOpen}
                        changeOnderhoudState={changeOnderhoudStatus}
                        persistOnderhoudHeader={persistOnderhoudHeader}
                        persistOnderhoudFiets={persistOnderhoudFiets}
                    />
                    <OnderhoudRegels
                        rowType="arbeid"
                        onderhoud={onderhoud}
                        requestOpenRow={requestOpenRow}
                        isEditAllowed={isEditAllowed}
                        persistOnderhoudRegel={persistOnderhoudRegel}
                        removeOnderhoudRegel={removeOnderhoudRegel}
                        requestEmptyOnderhoudRegel={requestEmptyOnderhoudRegel}
                    />
                    <OnderhoudRegels
                        rowType="materiaal"
                        onderhoud={onderhoud}
                        requestOpenRow={requestOpenRow}
                        isEditAllowed={isEditAllowed}
                        persistOnderhoudRegel={persistOnderhoudRegel}
                        removeOnderhoudRegel={removeOnderhoudRegel}
                        requestEmptyOnderhoudRegel={requestEmptyOnderhoudRegel}
                    />
                    <OnderhoudNotitie
                        initialNotitie={onderhoud.notitie}
                        persistOnderhoudNotitie={persistOnderhoudNotitie}
                    />
                    <OnderhoudDocumentOverview
                        onderhoudId={onderhoud.id}
                        isEditAllowed={isEditAllowed}
                    />
                    <OnderhoudApkOverview
                        onderhoudId={onderhoud.id}
                    />

                    {isEditAllowed && (
                        <>
                            <hr />
                            <div className="alignRight">
                                <Tooltip title="Verwijder onderhoud" placement={"right-end"}>
                                    <IconButton onClick={deleteOnderhoud}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </>)}
                    <StyledSnackbar message={message.value} type={message.type} resetMessage={() => setMessage({})} />
                    {/*<hr/>*/}
                    {/*{"ohs?"+JSON.stringify(onderhoud)}*/}
                    {/*<hr/>*/}
                    {/*{"IEA?"+JSON.stringify(isEditAllowed)}*/}
                    {/*<hr/>*/}
                    {/*{"Status?"+JSON.stringify(onderhoudStatus)}*/}
                </div>
                :
                <p>Even geduld ...</p>
            }
        </>
    );
});

export default OnderhoudDetail;
