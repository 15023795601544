import React, { useEffect, useState } from "react";
import "./Materiaal.scss";
import { formatCurrency, truncateText } from "../../../utils/util";


const MateriaalMobile = (({ initialMateriaal, showMessage }) => {

    const [materiaal, setMateriaal] = useState(initialMateriaal);
    const [viewDetails, setViewDetails] = useState(false);

    useEffect(() => {
        setMateriaal(initialMateriaal);
    }, [initialMateriaal]);

    const fieldNames =
        ["omschrijving", "voorraad"]
    const detailsFieldNames =
        ["eanCode", "artikelnummer", "leverancier", "inkoopPrijs", "prijs"]

    const columnSpan = (fieldName) => {
        switch (fieldName) {
            case 'omschrijving':
                return 6;
            default:
                return 1;
        }
    }
    const className = (fieldName) => {
        switch (fieldName) {
            case 'omschrijving':
                return 'alignLeft mobile';
            default:
                return 'alignCenter mobile';
        }
    }
    const content = (fieldName) => {
        switch (fieldName) {
            case 'omschrijving':
                return truncateText(materiaal[fieldName])
            case 'prijs':
            case 'inkoopPrijs':
                return `€ ${formatCurrency(materiaal[fieldName])}`
            default:
                return materiaal[fieldName];
        }
    }
    const detailsContent = (fieldName) => {
        switch (fieldName) {
            case 'omschrijving':
                return detailsFieldNames.reduce((acc, fn) =>
                    acc + `${fn}: ${(content(fn))}<br/>`, materiaal['omschrijving']+"<br/>")
            default:
                return materiaal[fieldName];
        }
    }
    const toggleViewDetails = () => setViewDetails(!viewDetails)

    return (
        <tr className={"materiaal"} onClick={toggleViewDetails}>
            {!viewDetails && fieldNames.map((fieldName, index) => (
                <td colSpan={columnSpan(fieldName)}
                    className={className(fieldName)}
                    key={fieldName}>
                    {content(fieldName)}</td>
            ))}
            {viewDetails && fieldNames.map((fieldName, index) => (
                <td colSpan={columnSpan(fieldName)}
                    className={"detail"}
                    key={fieldName}
                    dangerouslySetInnerHTML={{ __html: detailsContent(fieldName) }}></td>
            ))}
        </tr >
    );
});

export default MateriaalMobile;
