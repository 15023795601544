import React, {useState} from "react";
import "./MateriaalOverview.scss";
import Materiaal from "./Materiaal/Materiaal";
import usePrivateApi from "../../hooks/usePrivateApi";
import OverviewTable from "../../components/OverviewTable/OverviewTable";
import Tooltip from "@mui/material/Tooltip";
import IconButton from '@mui/material/IconButton';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import StyledSnackbar from "../../components/StyledSnackbar/StyledSnackbar";
import {useNavigate} from "react-router-dom";
import QrCodeIcon from "@mui/icons-material/QrCode";
import {ReactComponent as EanCodeIcon} from "../../Icons/eancode.svg";

function MateriaalOverview() {

    const navigate = useNavigate();

    const [showInkoop, setShowInkoop] = useState(false);
    const [showEan, setShowEan] = useState(false);
    const [isBestelAdvies, setIsBestelAdvies] = useState(false);
    const [isQr, setIsQr] = useState(false);
    const [message, setMessage] = useState({});
    const [deletedId, setDeletedId] = useState()
    const [isAnyRowOpen, setIsAnyRowOpen] = useState(false);

    const navigateToMateriaalNieuw = () => {
        navigate("/materiaal/nieuw");
    }

    const toggleShowInkoop = () => setShowInkoop(!showInkoop)
    const toggleShowEan = () => setShowEan(!showEan)
    const toggleIsBestelAdvies = () => {
        setIsBestelAdvies(!isBestelAdvies)
        setIsAnyRowOpen(false)
    }
    const toggleQr = () => setIsQr(!isQr)

    const api = usePrivateApi();

    const requestOpenRow = (request) => {

        if (request === 'open' && isAnyRowOpen) {
            setMessage({value: "Sluit eerst de open regel.", type: 'warning'});
            return false;
        } else {
            setIsAnyRowOpen(request === 'open');
            return true;
        }
    };
    const resetOpenRow = () => setIsAnyRowOpen(false)

    const removeMateriaal = (materiaalId) => {
        api.removeMateriaal(materiaalId)
            .then(
                () => {
                    setDeletedId(materiaalId);
                    setMessage({value: `Materiaal verwijderd`, type: 'success'});
                },
                error => {
                    setMessage({
                        value: "Materiaal is NIET verwijderd: het is in gebruik.",
                        type: 'error'
                    });
                }
            );
    }


    return (
        <div className="MateriaalOverview">
            <h1>{isBestelAdvies ? "Besteladvies" : "Materiaal"}
                <Tooltip title="Nieuw materiaal" placement="top">
                    <IconButton onClick={navigateToMateriaalNieuw}>
                        <PlaylistAddIcon/>
                    </IconButton>
                </Tooltip>
                {showInkoop ?
                    <Tooltip title="Verberg inkoopprijs" placement="top">
                        <IconButton onClick={toggleShowInkoop}>
                            <ToggleOnIcon className={"toggleOnIcon"}/>
                        </IconButton>
                    </Tooltip> :
                    <Tooltip title="Toon inkoopprijs" placement="top">
                        <IconButton onClick={toggleShowInkoop}>
                            <ToggleOffIcon className={"toggleOffIcon"}/>
                        </IconButton>
                    </Tooltip>}
                <Tooltip title="Besteladvies" placement="top">
                    <IconButton onClick={toggleIsBestelAdvies}>
                        <ShoppingCartIcon className={isBestelAdvies ? "isBestelAdvies" : ""}/>
                    </IconButton>
                </Tooltip>
                <Tooltip title="QR code" placement="top">
                    <IconButton onClick={toggleQr}>
                        <QrCodeIcon className={isQr ? "isQr" : ""}/>
                    </IconButton>
                </Tooltip>
                <Tooltip title="EAN code" placement="top">
                    <IconButton onClick={toggleShowEan}>
                        <EanCodeIcon className={showEan ? "isQr" : ""}/>
                    </IconButton>
                </Tooltip>
            </h1>

            <div className="Materiaal">

                <OverviewTable
                    dataFetchCall={isBestelAdvies ? api.getBestelAdvies : api.getAllMateriaal}
                    withFilter={false}
                    withSearch={true}
                    withFooter={true}
                    withTotalValue={isBestelAdvies && showInkoop}
                    deletedId={deletedId}
                    resetOpenRow={resetOpenRow}
                    headers={[
                        (showEan ? {displayName: 'EAN code', className: 'seven alignLeft', sortName: 'eanCode', colSpan: 1} :
                            {displayName: 'Artikelnummer', className: 'seven alignLeft', sortName: 'artikelCode', colSpan: 1}),
                        {displayName: 'Leverancier', className: 'seven alignLeft', sortName: 'leverancier', colSpan: 1},
                        {displayName: 'Locatie', className: 'seven alignLeft', sortName: 'locatie', colSpan: 1},
                        {displayName: 'Omschrijving', className: 'thirtyfive alignLeft', sortName: 'omschrijving', colSpan: 6},
                        (showInkoop ? {displayName: 'Inkoop',className: "seven alignCenter",sortName: 'inkoopPrijs',colSpan: 1,} : undefined),
                        (showInkoop && isBestelAdvies ? {displayName: 'Voorraadwaarde',className: "seven alignCenter",sortName: 'voorraadWaarde',colSpan: 1,} : undefined),
                        {displayName: 'Verkoop', className: "seven alignCenter", sortName: 'prijs', colSpan: 1},
                        {displayName: 'Minimaal', className: "seven alignCenter", sortName: 'minVoorraad', colSpan: 1},
                        {displayName: 'Maximaal', className: "seven alignCenter", sortName: 'maxVoorraad', colSpan: 1},
                        {displayName: 'Voorraad', className: "seven alignCenter", sortName: 'voorraad', colSpan: 1},
                        (!isBestelAdvies ? {displayName: 'BTW',className: "seven alignCenter",sortName: 'btw',colSpan: 1} : undefined),
                        (isBestelAdvies ? {displayName: 'Bestel advies',className: "seven alignCenter",sortName: 'bestelAdvies',colSpan: 1,} : undefined),
                    ].filter(x => x !== undefined)}
                    mapDataRow={row => (
                        <Materiaal
                            showInkoop={showInkoop}
                            showEan={showEan}
                            isBestelAdvies={isBestelAdvies}
                            isQr={isQr}
                            requestOpenRow={requestOpenRow}
                            initialMateriaal={row}
                            removeMateriaal={removeMateriaal}
                            key={row.id}
                            showMessage={setMessage}/>)
                    }
                />
                {/*<hr/>*/}
                {/*{"iaro "+JSON.stringify(isAnyRowOpen)}*/}
            </div>
            <StyledSnackbar message={message.value} type={message.type} resetMessage={() => setMessage({})}/>
        </div>
    );
}

export default MateriaalOverview;
